@import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400..700&family=Reem+Kufi+Fun:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=El+Messiri:wght@400..700&family=Reem+Kufi+Fun:wght@400..700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.get-in-touch {
  max-width: 800px;
  margin: 50px auto;
  position: relative;

}
.get-in-touch .title {
  font-family: "Reem Kufi Fun", serif;
  font-optical-sizing: auto;
  
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 3.5em;
  line-height: 48px;
  padding-bottom: 10px;
  padding-top: 40px;
   
   
}
.title2{
  text-align: center;
  font-family: "Reem Kufi Fun", serif;
  color: #2A773D;
  font-size: 1.5em;
}

 .form-field {
  position: relative;
  margin: 32px 0;
direction: rtl;
}
.input-text {
  display: block;
  width: 100%;
  height: 36px;
  border-width: 0 0 2px 0;
  border-color: #2A773D;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
.contact-form .input-text:focus {
  outline: none;
  margin-right:100px;
}
/* .contact-form .input-text:focus + .label,
.contact-form .input-text.not-empty + .label {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
} */
.contact-form .label {
  position: absolute;
  right: 0px;
  bottom:  25px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #929191;
  cursor: text;
  font-family: "Cairo", serif;
 
}
 .submit-btn {
 
  display: inline-block;
  background-color: #fff;
   background-image: linear-gradient(125deg,#2A773D,#2A773D);
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  padding: 8px 16px;
  border: none;
  width:200px;
  cursor: pointer;
}

/* CSS */
.button-82-pushable {
  font-family: "Reem Kufi Fun", serif;
  position: relative;
  top: 0;
  
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-82-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

.button-82-edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(159, 100%, 11%) 0%,
    hsl(159, 100%, 11%) 0%,
    hsl(159, 100%, 11%) 0%,
    hsl(159, 100%, 11%) 0%,
  );
}

.button-82-front {
  display: block;
  position: relative;
  padding: 12px 27px;
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  background:#2A773D;
  will-change: transform;
  transform: translateY(-4px);
  transition:
    transform
    600ms
    cubic-bezier(.3, .7, .4, 1);
}

@media (min-width: 768px) {
  .button-82-front {
    font-size: 1.25rem;
    padding: 12px 42px;
  }
}

.button-82-pushable:hover {
  filter: brightness(110%);
  -webkit-filter: brightness(110%);
}

.button-82-pushable:hover .button-82-front {
  transform: translateY(-6px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.button-82-pushable:hover .button-82-shadow {
  transform: translateY(4px);
  transition:
    transform
    250ms
    cubic-bezier(.3, .7, .4, 1.5);
}

.button-82-pushable:active .button-82-shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.button-82-pushable:focus:not(:focus-visible) {
  outline: none;
}